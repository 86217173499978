import { createStore } from 'vuex';
import { pushNotifications } from '@/../vendors/appcomponents/src/helpers/pushNotifications';
import { getClientLang } from '@/helpers/lang';
import * as storeComponents from '@/../vendors/appcomponents/src/store/index';
import access from './modules/access';

const modules = {
  ...storeComponents?.modules,
  access
};

const getDefaultState = () => ({
  error: null,
  keyUpdate: 0,
  lang: localStorage?.locale ?? 'ru'
});
const defState = getDefaultState();
getClientLang().then((lang) => {
  const setLang = localStorage?.locale ?? lang;
  defState.lang = ['ru', 'en'].includes(setLang) ? setLang : 'ru';
});
export default createStore({
  state: defState,
  getters: {
    error: (state) => state.error,
    keyUpdate: (state) => state.keyUpdate,
    lang: (state) => state.lang
  },
  mutations: {
    SET_ERROR: (state, payload) => (state.error = payload),
    SET_KEY_UPDATE: (state, payload) => (state.keyUpdate = payload),
    RESET_STATE: (state) => Object.assign(state, defState),
    SET_LANG: (state, payload) => {
      state.lang = payload;
      localStorage.locale = payload;
    }
  },
  actions: {
    updateRouteKey(context) {
      this.commit('SET_KEY_UPDATE', context.getters.keyUpdate + 1);
    },
    setError(context, error) {
      this.commit('SET_ERROR', error);
    },
    logout({ commit, getters, dispatch }) {
      return dispatch('user/logoutUser').finally(() => {
        pushNotifications.unsubscribeFrom(getters.userId);
        commit('user/SET_TYPE', '');
        commit('user/SET_ID_SESSION', '');
        commit('user/RESET_STATE');

        Object.keys(modules).forEach((k) => {
          commit(`${k}/RESET_STATE`);
        });
      });
    },
    resetStateModules({ commit }) {
      Object.keys(modules).forEach((k) => {
        commit(`${k}/RESET_STATE`);
      });
    },
    setLang({ commit }, lang) {
      commit('SET_LANG', lang);
    }
  },
  modules
});
