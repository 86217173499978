export const PATH_SIGN_IN_TYPES = '/profile/sing-in/types';
export const PATH_SIGN_IN_SUB_TYPES = '/:type/profile/sing-in/types';
export const PATH_CENTER_VIDEOS = '/center/videos';
export const PATH_MAIN_VIDEOS = '/main/videos';
export const PATH_EVENTS = '/events';
export const PATH_SIGN_IN_AUTH = '/auth';
export const PATH_ARTICLES = '/articles';
export const PATH_MODULES = '/modules';
export const PATH_MEMBERSHIP = '/membership';
export const PATH_MODULE_SEARCHNEARBY = '/search_nearby';
export const PATH_SCHOOLS = '/schools';
export const PATH_SCHOOL_BONUSES = '/school/:school_id/bonuses';
export const PATH_AUTHORS = '/authors';
export const PATH_SCHOOL = '/school/:school_id';
export const PATH_COMPANY = '/company/:school_id';
export const PATH_PROFILE = '/profile';
export const PATH_PROFILE_VIDEOS = '/profile_video';
export const PATH_MY_CALENDAR = '/profile/my_calendar';
export const PATH_MEMBERSHIP_PROFILE = '/membership/profile';
export const PATH_PROFILE_EDIT = '/profile/edit';
export const PATH_ADD_SCHOOL = '/add-school';
export const PATH_SERVICE = '/:type/school/:school_id/service/:service_id';
export const PATH_SERVICES = '/:type/school/:school_id/services';
export const PATH_STUDENTS = '/:type/school/:school_id/students';
export const PATH_STUDENT = '/:type/school/:school_id/student/:student_id';
export const PATH_TEACHERS = '/:type/school/:school_id/teachers';
export const PATH_TEACHER = '/:type/school/:school_id/teacher/:teacher_id';
export const PATH_ADMINISTRATORS = '/school/:school_id/administrators/:type';
export const PATH_WORK_TIMES = '/:type/school/:school_id/work_times';
export const PATH_SCHEDULE = '/:type/school/:school_id/schedule';
export const PATH_FINANCE = '/:type/school/:school_id/finance';
export const PATH_TIMETABLE = '/:type/school/:school_id/timetable';
export const PATH_NOTIFICATIONS = '/notifications';
export const PATH_USER = '/user/:user_id';
export const PATH_POST = '/post/:post_id';
export const PATH_VIDEO_MODERATOR = '/video/:post_id/:moderator';
export const PATH_BALANCE = '/balance';
export const PATH_POST_MODERATOR = '/post/:post_id/:moderator';
export const PATH_REQUESTS = '/:type/school/:school_id/requests';
export const PATH_STUDENT_FILES = '/:type/school/:school_id/student_files';
// FORMS
export const PATH_FORMS = '/schools/:school_id/forms';
export const PATH_FORM_QUESTIONS =
  '/schools/:school_id/forms/:form_id/questions';
export const PATH_FORM_ANSWERS = '/schools/:school_id/forms/:form_id/answers';
export const PATH_FORMS_BY_CLIENTS = '/schools/:school_id/forms_by_client';
export const PATH_FILES_BY_CLIENTS = '/schools/:school_id/files_by_student';
export const PATH_VIDEOCONFERENCE_BY_COMPANY =
  '/schools/:school_id/videoconference';
export const PATH_FORMS_ANSWERS_BY_CLIENTS =
  '/schools/:school_id/form/:form_id/forms_answers_by_client';
export const PATH_FORMS_QUESTIONS_BY_CLIENTS =
  '/schools/:school_id/form/:form_id/forms_questions_by_client';

// TICKETS
export const PATH_TICKETS = '/schools/:school_id/tickets';
export const PATH_TICKETS_ITEMS =
  '/schools/:school_id/tickets/:ticket_id/items';

export const PATH_SERVICES_GROUPS = '/:type/schools/:school_id/services_groups';
export const PATH_STUDENTS_GROUPS = '/:type/schools/:school_id/students_groups';
export const PATH_VISITS_STUDENTS = '/:type/schools/:school_id/visits_students';

export const PATH_CLIENTS = '/:type/school/:school_id/clients';
export const PATH_CLIENT = '/:type/school/:school_id/clients/:student_id';

export const PATH_STOCK_BY_COMPANY = '/schools/:school_id/stock';

// CENTER
export const PATH_CENTER_MAIN = '/center/main';
export const PATH_CENTER_POSTS = '/center/posts';
export const PATH_CENTER_POSTS_TAG = '/center/posts/:tag';
export const PATH_CENTER_POSTS_CATEGORY = '/center/posts/category/:category_id';
export const PATH_CENTER_LESSONS = '/center/lessons';
export const PATH_CENTER_LESSONS_CATEGORY = '/center/lessons/:category_id';
export const PATH_CENTER_LESSONS_THEME = '/center/lessons/:theme_id';
export const PATH_CENTER_VIDEO = '/video/:post_id';
export const PATH_CENTER_BANNER = '/center/banner/:banner_id';
export const PATH_CENTER_THEME = '/center/theme/:theme_id';
export const PATH_CENTER_ORGANISATIONS = '/center/organisations';
export const PATH_CENTER_ORGANISATIONS_COMPANY =
  '/center/organisations/:category_id';
export const PATH_CENTER_EXPERTS = '/center/experts';
export const PATH_CENTER_STOCK = '/center/stock';
export const PATH_CENTER_QUESTIONS = '/center/forum';
export const PATH_CENTER_QUESTION = '/center/forum/:question_id';
export const PATH_CENTER_QUESTIONS_THEME = '/center/theme-forum/:theme_id';
export const PATH_CENTER_QUESTIONS_CATEGORY =
  '/center/category-forum/:category_id';
export const PATH_CENTER_PROFILE = '/profile';
export const PATH_CENTER_MY_COMPANIES = '/profile/my_companies';
export const PATH_WEB_CENTER_MY_COMPANIES = '/web/profile/my_companies';
export const PATH_WEB_CENTER_COMPANY_TIMETABLE =
  '/web/timetable_company/:company_id';
export const PATH_WEB_CENTER_COMPANY_RECORD = '/web/record_company/:company_id';
export const PATH_WEB_CENTER_COMPANY_SERVICES =
  '/web/services_company/:company_id';
export const PATH_CENTER_VIDEO_CONFERENCE = '/center/videoconference/:room';
export const PATH_CENTER_CHAT = '/center/chat';
