<!-- eslint-disable @intlify/vue-i18n/no-raw-text -->
<template>
  <IonMenu
    side="start"
    menu-id="profile-menu"
    class="profile-menu"
    content-id="main"
    :swipe-gesture="false"
  >
    <IonContent>
      <IonHeader>
        <IonToolbar>
          <IonTitle v-if="false">
            {{ $t('MENU') }}
          </IonTitle>
          <SelectLocale slot="end" interface="popover" />
        </IonToolbar>
      </IonHeader>
      <IonList class="list-menu">
        <template v-if="menu === MENU_MAIN">
          <IonItem
            lines="none"
            button
            :detail="false"
            @click="showModalSupport = true"
          >
            <IonLabel>{{ $t('SUPPORT') }}</IonLabel>
          </IonItem>
          <div class="margin-top"></div>
          <IonItem
            v-if="hasSession"
            lines="none"
            :detail="false"
            button
            @click.prevent="menu = SUBMENU_CONTROL_ACCOUT"
            >{{ $t('CONTROL-ACCOUNT') }}
          </IonItem>
          <IonItem
            lines="none"
            :detail="false"
            button
            @click.prevent="onPolicy"
          >
            <IonLabel>{{ $t('PRIVACY-POLICY') }}</IonLabel>
          </IonItem>
          <IonItem
            lines="none"
            :detail="false"
            button
            @click.prevent="termsOfUse"
          >
            <IonLabel>{{ $t('TERMS-OF-USE') }}</IonLabel>
          </IonItem>
          <IonItem
            v-if="hasSession"
            lines="none"
            :detail="false"
            button
            @click.prevent="onLogout"
            >{{ $t('EXIT') }}
          </IonItem>
        </template>
        <template v-if="menu === SUBMENU_CONTROL_ACCOUT">
          <IonItem
            lines="none"
            button
            :detail="false"
            @click="menu = MENU_MAIN"
          >
            <IonLabel>{{ $t('BACK') }}</IonLabel>
          </IonItem>
          <IonItem lines="none" button :detail="false" @click="goToProfileEdit">
            <IonLabel>Настройка профиля</IonLabel>
          </IonItem>
          <div class="margin-top"></div>
          <IonItem lines="none" button :detail="false" @click="onDeleteAccount">
            <IonLabel>{{ $t('DELETE-ACCOUNT') }}</IonLabel>
          </IonItem>
        </template>
      </IonList>
      <ModalSupport
        :show="showModalSupport"
        :name="user.firstname"
        @hide="hideModalSupport"
      />
      <ModalDeleteAccount
        :show="showModalDeleteAccount"
        @hide="hideModalDeleteAccount"
      />
    </IonContent>
  </IonMenu>
</template>
<script>
import { openNewWindow } from '@/../vendors/appcomponents/src/helpers/openNewWindow';
import {
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonTitle,
  IonToolbar,
  menuController
} from '@ionic/vue';
import { checkmarkCircleOutline } from 'ionicons/icons';
import { mapActions, mapGetters } from 'vuex';
import SelectLocale from '@/../vendors/appcomponents/src/components/ui/SelectLocale.vue';
import ModalSupport from '@/../vendors/appcomponents/src/components/ui/ModalSupport.vue';
import ModalDeleteAccount from '@/../vendors/appcomponents/src/components/ui/ModalDeleteAccount.vue';

const MENU_MAIN = 'main';
const SUBMENU_CONTROL_ACCOUT = 'control_account';

export default {
  name: 'Menus',
  components: {
    IonMenu,
    IonContent,
    IonList,
    IonItem,
    IonHeader,
    IonToolbar,
    IonTitle,
    ModalSupport,
    IonLabel,
    SelectLocale,
    ModalDeleteAccount
  },
  data: () => ({
    showModalSupport: false,
    showModalDeleteAccount: false,
    menu: MENU_MAIN,
    MENU_MAIN,
    SUBMENU_CONTROL_ACCOUT,
    checkmarkCircleOutline
  }),
  computed: {
    ...mapGetters('user', ['user', 'session', 'hasSession'])
  },
  methods: {
    ...mapActions(['logout']),
    ...mapActions('chat', ['setCabinetChat']),
    onLogout() {
      this.logout();
      this.$router.replace('/');
      menuController.close('profile-menu');
    },
    goToProfileEdit() {
      this.$router.push({ name: this.$ROUTES.routeProfileEdit().name });
      menuController.close('profile-menu');
    },
    onPolicy() {
      openNewWindow('https://xn--b1adeadlc0aoe3s.xn--p1ai/policy');
    },
    termsOfUse() {
      openNewWindow('https://xn--b1adeadlc0aoe3s.xn--p1ai/termsofuse');
    },
    hideModalSupport() {
      this.showModalSupport = false;
    },
    onDeleteAccount() {
      this.showModalDeleteAccount = true;
    },
    hideModalDeleteAccount() {
      this.showModalDeleteAccount = false;
    }
  }
};
</script>
<style lang="scss">
.profile-menu {
  --width: 300px;
}
.list-menu {
  display: flex;
  flex-direction: column;
  height: calc(100% - 45px);
  .margin-top {
    margin-top: auto;
  }
  padding-bottom: calc(var(--ion-safe-area-top, 0) + 10px);
}
</style>
