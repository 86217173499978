export function clearFields() {
  this.fields = { ...this.emptyFields };
  this.errorFields = {};
  this.error = '';
  return true;
}

export function objToFormData(obj) {
  const fd = new FormData();
  Object.keys(obj).forEach((key) => {
    let val = obj[key];
    if (typeof val === 'boolean') {
      val = val ? 1 : 0;
    }
    if (typeof val === 'undefined') {
      val = '';
    }
    fd.append(key, val === null ? '' : val);
  });

  return fd;
}

export function checkFields(
  e,
  nameFields = 'fields',
  nameErros = 'errorFields',
  nameRequired = 'requiredFields'
) {
  let hasErrors = false;
  if (typeof e === 'object' && e?.response?.data?.errors) {
    const { errors } = e.response.data;
    Object.keys(errors).forEach((i) => {
      this[nameErros][i] = errors[i].join(', ');
    });
  } else {
    Object.keys(this[nameFields]).forEach((i) => {
      if (
        this[nameRequired].indexOf(i) >= 0 &&
        !this[nameFields][i] &&
        this[nameFields][i] !== 0
      ) {
        this[nameErros][i] = this.$t('REQUIRED-TO-FILL');
        hasErrors = true;
      } else {
        this[nameErros][i] = false;
      }
    });
  }
  return hasErrors;
}
