import { AppMetrica, AppMetricaPlugin } from 'capacitor-appmetrica-plugin';

export class AnalyticsService {
  private appmetrica: AppMetricaPlugin = AppMetrica;

  async initialization() {
    try {
      await this.appmetrica.activate({
        apiKey: '03254bf0-cee0-4d2c-b8c6-c513b9c6d26d',
        logs: true
      });
    } catch (e) {
      console.error(e);
    }
  }

  //   async logEvent(name: string, params?: Object) {
  //     await this.appmetrica.logEvent(name, params);
  //   }

  async setUserProfileID(id: string) {
    return this.appmetrica.setUserProfileId({ id });
  }
}
